.notDraggable {
  /* user-drag: none; */
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.tablePzero tr td {
  padding: 0 !important;
}


html,
body {
  height: 100%
}

*::-webkit-scrollbar-track {
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  border-radius: 10px;
  /* background-color: red; */
}

*::-webkit-scrollbar {
  width: 6px;
  /* background-color: #f5f5f5; */
}


  /* background-color: #171923;; */

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color: #9c9c9b;
}

.sector:hover {
  fill: gray;
}



.rectShadow {
  filter: drop-shadow(0 0 3px gray);
  border-radius: 10px;
}

.menuWrapper::before {
  position: absolute;
  left: 15px;
  top: 0;
  width: 2px;
  height: 100%;
  /* background: #111E34; */
  content: "";
}

.menuWrapperItem::before {
  position: absolute;
  /* background: #111E34; */
  width: 22px;
  top: 15px;
  left: -18px;
  height: 2px;
  content: "";
}

.menuBeforeLight::before {
  background: #EFEFEF;
  ;
}

.menuBeforeDark::before {
  background: #111E34;
  ;
}

.columnsShowHideDesktop {
  position: absolute;
  top: 48px;
  left: -71px;
  z-index: 10000 !important;
}

.dottedWithSpacing {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23868C8FFF' stroke-width='1' stroke-dasharray='7' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.orangeBg {
  background-color: #FF7500 !important;
  color: #EFEFEF
}

.react-select-material {
  width: 200px;
}

.propsPicker {
  /* z-index:2 !important;; */
  width: 260px;
}

.noSelect {
  cursor: default !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -o-user-select: none !important;
  user-select: none !important;
}

.sector:hover {
  transition: all 0.5;
  opacity: 0.5;
}

.italic {
  font-weight: normal;
  font-style: italic;
}

.sectorHover:hover {
  opacity: 0.5;
}

.h-100 {
  height: 100%;
}



.hideScrollBar::-webkit-scrollbar {
  display: none
}

.tag-view-panel-header-contents {
  display: none;
}

/* .recharts-brush-slide {
  stroke: blue;
  fill: blue
}

.recharts-brush-traveller {
  stroke: green;
  fill: green;
}

.recharts-brush-texts {
  stroke: black;
  font-size: 12px;
  font-weight: 400;
  /* fill: green; */
/* } */
















.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  /* background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+'); */
  background-position: bottom right;
  padding: 0 3px 3px 0;

  border-right: solid 5px #FF7500;
  border-bottom: solid 5px #FF7500;
}

.react-resizable-handle-sw {
  bottom: 0;
  left: 0;
  cursor: sw-resize;
  transform: rotate(90deg);
}

.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: se-resize;
}

.react-resizable-handle-nw {
  top: 0;
  left: 0;
  cursor: nw-resize;
  transform: rotate(180deg);
}

.react-resizable-handle-ne {
  top: 0;
  right: 0;
  cursor: ne-resize;
  transform: rotate(270deg);
}

.react-resizable-handle-w,
.react-resizable-handle-e {
  top: 50%;
  margin-top: -10px;
  cursor: ew-resize;
}

.react-resizable-handle-w {
  left: 0;
  transform: rotate(135deg);
}

.react-resizable-handle-e {
  right: 0;
  transform: rotate(315deg);
}

.react-resizable-handle-n,
.react-resizable-handle-s {
  left: 50%;
  margin-left: -10px;
  cursor: ns-resize;
}

.react-resizable-handle-n {
  top: 0;
  transform: rotate(225deg);
}

.react-resizable-handle-s {
  bottom: 0;
  transform: rotate(45deg);
}
























































.bubble {
  height: -moz-fit-content;
  height: fit-content;
  display: inline-block;
  position: relative;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid black;
  background: white;

  clear: both;
}

.bubble:before {
  content: "";
  position: absolute;
  bottom: -50px;
  height: 50px;
  width: 90px;
}

.bubble.left {
  float: left;
}

.bubble.left:before {
  border-radius: 0 0 100%;
  box-shadow: -2px -2px 0 0 #000 inset, -23px 0 0 0 #fff inset, -25px -2px 0 0 #000 inset;
  left: 0;
}

.bubble.right {
  float: right;
  margin: 10px 10px 60px 100px;
}

.bubble.right:before {
  border-radius: 0 0 0 100%;
  box-shadow: 2px -2px 0 0 #000 inset, 23px 0 0 0 #fff inset, 25px -2px 0 0 #000 inset;
  right: 0;
}



.fcHandler {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.fcHandlerLight {
  background: #fff;
}

.fcHandlerDark {
  /* background: #001129; */
}

.mcalendar_weekly_visit_light{background: #718096;}
.mcalendar_weekly_visit_dark{background: #718096;}


.mcalendar_planned{background: #4B89F5;}
.mcalendar_late{background: #FF666A;}
.mcalendar_complete{background:#42D7B5 ;}
.mcalendar_reactive{background:#FFD400 ;}
.mcalendar_cancelled{background:#8F8F8F ;}
.mcalendar_inProgress{background:#008080 ;}

.react-datepicker-zindex{
  z-index: 100000 !important;
}

.react-datepicker__navigation--years::before {
  border-color: #666;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: '';
  display: block;
  height: 9px;
  left: 11px;
  position: absolute;
  width: 9px;
}

.react-datepicker__navigation--years-upcoming::before {
  top: 17px;
  transform: rotate(315deg);
}

.react-datepicker__navigation--years-previous::before {
  top: 6px;
  transform: rotate(135deg);
}

.react-switch-handle{
  background: #A0AEC0 !important;
}

.chakra-modal__overlay{
  /* zoom: 1 !important; */
}

.reactDatePickerBgLightMode {
  background: #fff !important;
}
.reactDatePickerBgDarkMode {
  background: #040016 !important;
}
.reactDatePickerBgDarkModeModal {
  background: #111e34 !important;
}

.w-100{
  width: 100% !important;
}

.w-auto{
  width: auto !important;  
}

.w-fit-content{
  width: -moz-fit-content !important;
  width: fit-content !important;  
}

.image-gallery-left-nav, .image-gallery-right-nav{
  padding: 10px !important;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{
  width: 30px !important;
}


@media all {
    .page-break {
      display: none;
    }
  }
  
  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: always;
    }
  }
  
  @page {
    size: auto;
    margin: 20mm;
  }



/* cyrillic-ext */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/ec159349637c90ad-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/513657b02c5c193f-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek-ext */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/fd4db3eb5472fc27-s.woff2) format('woff2');
  unicode-range: U+1F00-1FFF;
}
/* greek */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/51ed15f9841b9f9d-s.woff2) format('woff2');
  unicode-range: U+0370-0377, U+037A-037F, U+0384-038A, U+038C, U+038E-03A1, U+03A3-03FF;
}
/* vietnamese */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/05a31a2ca4975f99-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/d6b16ce4a6175f26-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Inter_41e5c4';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(/_next/static/media/c9a5bc6a7c948fb0-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Inter_Fallback_41e5c4';src: local("Arial");ascent-override: 90.49%;descent-override: 22.56%;line-gap-override: 0.00%;size-adjust: 107.06%
}.__className_41e5c4 {font-family: '__Inter_41e5c4', '__Inter_Fallback_41e5c4';font-style: normal
}

